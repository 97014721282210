{
  "companyname": "Karde AS",
  "nav": {
    "nav0": "Home",
    "nav1": "About Memas",
    "nav2": "Privacy",
    "nav3": "NY NAV 3"
  },
  "services": {
    "today": "Today",
    "selfreport": "Self report",
    "brain": "Brain training",
    "dailyhelp": "Daily help",
    "entertainment": "entertainment",
    "contact": "Contact",
    "workhelp": "Work help",
    "phoneweekcalendar": "Week calendar"
  },
  "gui": {
    "appname": "Memas",
    "close": "Close",
    "landingpageheader": "Welcome to Memas",
    "firsttimeheader": "First time registration",
    "offlineheading": "You are offline",
    "aboutheader": "About memas",
    "privacyheader": "Privacy",
    "offlinetext": "Wait with your activities until you are back online",
    "onlineheading": "You are online",
    "onlinetext": "You can continue your work",
    "primaryusers": "Primary user of Memas",
    "calendar": "Calendar entries",
    "pcalendar": "Today",
    "memorygames": "Brain training",
    "images": "Images and categories of images",
    "pleasure": "Pleasure",
    "contact": "Contact",
    "selfreporting": "Self-report",
    "dailyhelp": "Daily help",
    "usermanuals": "Usermanual",
    "primarylanding": "Memas primaryuser",
    "of": "of",
    "question": "Question",
    "wrongOTPcode": "The code you entered does not exists - try again",
    "missingValue": "You must select an answer before you can continue",
    "nextquestion": "Next",
    "previousquestion": "Previous",
    "donequestions": "Back to start screen",
    "maxscreen": "Full screen",
    "imagelibrary": "Pictures",
    "noimagelibrary": "No pictures available",
    "radiostations": "Radio",
    "noradiostations": "Radio stations available",
    "videolibrary": "Videos",
    "novideolibrary": "Videos available",
    "stop": "Stop",
    "pause": "Pause",
    "play": "Play",
    "reset": "Reset",
    "calnav": "Days",
    "calmonth": "Month",
    "contactinformation": "Primary contact",
    "replay": "Play again",
    "completed": "Well done - you completed the task",
    "moves": "Moves:",
    "noimages": "No images",
    "noimagesfound": "There exists no images to show",
    "nomemoryimages": "Memory game",
    "nomemoryimagesfound": "No games are configured for you to play",
    "workhelp": "Work help",
    "picturememorygame": "Picture matching",
    "week": "Week",
    "addcalendarevent": "New appointment",
    "todayis": "Today is ",
    "settingsgohome": "If you see this page, click inside this box to go back to the start screen."
  },
  "sudoku": {
    "gamefinished": "Game finished",
    "newgame": "New game",
    "viewgame": "View game",
    "notes": "Notes",
    "on": "On",
    "off": "Off",
    "solve": "Solve",
    "reset": "Reset",
    "delete": "Delete"
  },
  "ms": {
    "title": "Minesweeper",
    "playagain": "Play again?",
    "yes": "Yes",
    "win": "Victory - you win",
    "loose": "You lost...",
    "instructions": "Avoid the mines, select a cell, right-click to mark a mine."
  },
  "time": {
    "beforelunch": "Before lunch",
    "lunch": "Lunch",
    "afterlunch": "Day",
    "dinner": "Dinner time",
    "afternoon": "Afternoon",
    "evening": "Evening",
    "night": "Night",
    "morning": "Morning"
  },
  "webgame": {
    "timehint": "The time in minutes you have played this game.",
    "timelabel": "Minutes",
    "timeprefix": ": ",
    "timesuffix": " min.",
    "notnumber": "Must be a number!"
  },
  "tooltip": {
    "help": "Context sensitive help in using Memas"
  },
  "primary": {
    "entercode": "Enter your code for start using Memas"
  },
  "secondaryuser": {
    "heading": "Registration of secondary users",
    "displayname": "Name",
    "email": "E-mail",
    "phone": "Phone",
    "skype": "Skype",
    "address": "Address",
    "postcode": "Post code",
    "place": "Place",
    "country": "Country",
    "subscriptiontype": "Agreement type",
    "subscriptionmodel1": "Free use - 30 days",
    "subscriptionmodel2": "Subscription for 3 months- auto renewal",
    "subscriptionmodel3": "Subscription for 1 year - auto renewal"
  },
  "primaryuser": {
    "heading": "Registration of primary users",
    "displayname": "Name",
    "email": "Email",
    "phone": "Phone",
    "skype": "Skype",
    "address": "Address",
    "postcode": "Post code",
    "place": "Place",
    "country": "Country",
    "registercode": "Registration code"
  },
  "cal": {
    "starttime": "Start time",
    "endtime": "End time",
    "title": "Title of event",
    "alldayevent": "All day event",
    "explanation": "More information",
    "image": "Image of person regarding event",
    "nocaldatatoday": "No calendar events registered for today",
    "nocaldataweek": "No week calendar"
  },
  "help": {
    "title": "How to use Memas",
    "headerhelp": "Context based help",
    "landingpage": "https://help.v4l.no/v4l/learner/startpage/",
    "todayswrittenassignments": "https://help.v4l.no/v4l/learner/todayswrittenassignemnts/",
    "todayswrittenassignments-assignedtask": "https://help.v4l.no/v4l/learner/todayswrittenassignemntstask/",
    "writetext": "https://help.v4l.no/v4l/learner/writetext/",
    "writetext-feedback": "https://help.v4l.no/v4l/learner/writetextfeedback/",
    "writetext-competencygoal": "https://help.v4l.no/v4l/learner/writetextcompetencygoal/",
    "peerreview": "https://help.v4l.no/v4l/learner/peerreview/",
    "assessmentfeedback": "https://help.v4l.no/v4l/learner/assessmentfeedback/",
    "assessmentfeedback-assignment": "https://help.v4l.no/v4l/learner/assessmentfeedbackassignment/",
    "assessmentfeedback-view": "https://help.v4l.no/v4l/learner/assessmentfeedbackview/",
    "resultpeerreview": "https://help.v4l.no/v4l/learner/resultpeerreview/",
    "configsettings": "https://help.v4l.no/v4l/learner/configsettings/",
    "writetextaflmethod": "https://help.v4l.no/v4l/learner/writetextaflmethod/",
    "writetextautofeedback": "https://help.v4l.no/v4l/learner/writetextautofeedback/"
  },
  "icon": {
    "save": "mdi-content-save-outline",
    "schemaupdate": "mdi-update",
    "close": "mdi-close",
    "help": "mdi-help",
    "next": "mdi-arrow-right-bold",
    "previous": "mdi-arrow-left-bold",
    "dots": "mdi-dots-vertical",
    "tryagain": "mdi-refresh",
    "maxscreen": "mdi-fullscreen",
    "stop": "mdi-radio-off",
    "pause": "mdi-pause",
    "play": "mdi-play",
    "reset": "mdi-notification-clear-all",
    "reload": "mdi-reload"
  },
  "wordgame": {
    "title": "Rephrase the word",
    "startgame": "Click to start game",
    "correct": "Correct answer - try again",
    "wrong": "Wrong answer - try again",
    "correctAnswer": "Correct answer",
    "proposedAnswer": "Your answer",
    "level": "Choose difficultylevel",
    "level1": "Easy",
    "level2": "Medium",
    "level3": "Hard",
    "level4": "Challenging",
    "easywords": "dog,cat,ask,who,out,in,up,not",
    "mediumwords": "house,roof,window,door,table,chair",
    "hardwords": "bicycle,airplane,inbetween,beyond,through",
    "complexwords": "roundabout,understanding,complexity,mysterious,challenging"
  },
  "numbergame": {
    "title": "Guess the {level}-digit number",
    "explanation": "Try to guess the secret {level}-digit number with unique digits from 1 to 9. - You have 10 attempts.",
    "guess": "Suggestion",
    "goods": "Goods",
    "corrects": "Feedback",
    "placeholder": "Enter your guess",
    "again": "Play again",
    "toastmaxinput": "Max {level} digits",
    "toastunique": "Please enter a {level}-digit number with digits from 1 to 9 (inclusive)",
    "toastnoduplicate": "No duplicate digits allowed",
    "reset": "Reset",
    "tooltipguess": "The green button indicates that one of the numbers you've indicated is present and correctly placed (it's not necessarily the number that's in the place indicated by the button). The blue button indicates that one of the digits you indicated is present in the code, but NOT in the right place.",
    "tooltipgoods": "Number of correct numbers in the wrong position",
    "tooltipcorrects": "White is wrong, blue is correct number, green is in right position",
    "win": "You won! The secret number was: {number}",
    "lost": "You lost... The secret number was: {number}"
  },
  "toast": {
    "calmove": "The calendar is not updated, calendar changes are controlled from the adminweb.",
    "calchanged": "The calendar is not updated, calendar changes are controlled from the adminweb.",
    "webgamesaved": "Time used on game is saved"
  },
  "selfreporting": {
    "completetitle": "You have completed the questionnaire",
    "completetext": "Congratulations well done !",
    "q1": {
      "q": "Who answered the questions?",
      "a1": "The PU her-/himself",
      "a2": "The SU by interviewing the PU ",
      "a3": "The SU based on observations of the PU "
    },
    "q2": {
      "q": "Yesterday and today, did you remember your medicines?",
      "a1": "I think i took all of my medicines",
      "a2": "I am afraid that I forgot most of my medicine",
      "a3": "I think I forgot to take some of my medicines",
      "a4": "I do not want to take the medicines",
      "a5": "Prefer not to answer."
    },
    "q3": {
      "q": "How would you describe your mood today?",
      "a1": "Tense, nervous, irritated, annoyed",
      "a2": "Bored, weary, gloomy, sad ",
      "a3": "Cheerful, happy, exited, lively",
      "a4": "Calm, serene, relaxed, carefree",
      "a5": "Prefer not to answer."
    },
    "q4": {
      "q": "Have you lately experienced a feeling of confusion",
      "a1": "No",
      "a2": "Some during the last days",
      "a3": "Noticeable worsening during the last days or few weeks",
      "a4": "Prefer not to answer."
    },
    "q5": {
      "q": "Have you lately experienced problems with remembering names you should remember (next of kin, friends, family members)",
      "a1": "No",
      "a2": "Some during the last days",
      "a3": "Noticeable worsening during the last days or few weeks",
      "a4": "Prefer not to answer."
    },
    "q6": {
      "q": "Have you lately experienced misplacing items at home?",
      "a1": "No",
      "a2": "Some during the last days",
      "a3": "Noticeable worsening during the last days or few weeks",
      "a4": "Prefer not to answer."
    },
    "q7": {
      "q": "Have you lately had problems finding items at home?",
      "a1": "No",
      "a2": "Some during the last days",
      "a3": "Noticeable worsening during the last days or few weeks",
      "a4": "Prefer not to answer."
    },
    "q8": {
      "q": "Have you lately had problems finding your way back home when out walking?",
      "a1": "No",
      "a2": "Some during the last days",
      "a3": "Noticeable worsening during the last days or few weeks",
      "a4": "Prefer not to answer."
    },
    "q9": {
      "q": "Have you lately had problems remembering how to perform procedural activities (for example using the coffee machine, TV-tuner/remote control or washing machine)?",
      "a1": "No",
      "a2": "Some during the last days",
      "a3": "Noticeable worsening during the last days or few weeks",
      "a4": "Prefer not to answer."
    },
    "q10": {
      "q": "Have you lately had problems with remembering names of or the words for things, items, places etc.?",
      "a1": "No",
      "a2": "Some during the last days",
      "a3": "Noticeable worsening during the last days or few weeks",
      "a4": "Prefer not to answer."
    },
    "q11": {
      "q": "Nausea. Have you during the last days suffered from nausea?",
      "a1": "No, not at all",
      "a2": "Yes, a little or sometimes",
      "a3": "Yes, quite a lot",
      "a4": "Prefer not to answer."
    },
    "q12": {
      "q": "Digestive. Have you had digestive troubles",
      "a1": "No, not at all",
      "a2": "Yes, a little or sometimes",
      "a3": "Yes, quite a lot",
      "a4": "Prefer not to answer."
    },
    "q13": {
      "q": "Poor appetite. Have you during the last days experienced poor appetite?",
      "a1": "No, not at all",
      "a2": "Yes, a little or sometimes",
      "a3": "Yes, quite a lot",
      "a4": "Prefer not to answer."
    },
    "q14": {
      "q": "Dizziness. Have you during the last days suffered from dizziness?",
      "a1": "No, not at all",
      "a2": "Yes, a little or sometimes",
      "a3": "Yes, quite a lot",
      "a4": "Prefer not to answer."
    },
    "q15": {
      "q": "Powerless. Have you during the last days felt yourself powerless?",
      "a1": "No, not at all",
      "a2": "Yes, a little or sometimes",
      "a3": "Yes, quite a lot",
      "a4": "Prefer not to answer."
    },
    "q16": {
      "q": "Sleep. Have you during the last days had trouble with your sleep?",
      "a1": "No, not at all",
      "a2": "Yes, a little or sometimes",
      "a3": "Yes, quite a lot",
      "a4": "Prefer not to answer."
    },
    "q17": {
      "q": "Language. Have you during the last days felt trouble with expressing yourself, e.g. difficulty finding the right words, difficulty to understand, a feeling of giving vague and incomplete answers?",
      "a1": "No, not at all",
      "a2": "Yes, a little or sometimes",
      "a3": "Yes, quite a lot",
      "a4": "Prefer not to answer."
    },
    "q18": {
      "q": "Reasoning. Have you during the last days had problems with reasoning and judgment, e.g. poor judgment in spending money, or poor personal care?",
      "a1": "No, not at all",
      "a2": "Yes, a little or sometimes",
      "a3": "Yes, quite a lot",
      "a4": "Prefer not to answer."
    },
    "q19": {
      "q": "Social. Have you during the last days had problems to maintain your social contacts or performing your hobbies and favourite activities?",
      "a1": "No, not at all",
      "a2": "Yes, a little or sometimes",
      "a3": "Yes, quite a lot",
      "a4": "Prefer not to answer."
    }
  }
}
