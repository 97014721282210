{
  "companyname": "Karde AS",
  "nav": {
    "nav0": "Hjem",
    "nav1": "Om Memas",
    "nav2": "Personvern",
    "nav3": "???"
  },
  "services": {
    "today": "I dag",
    "selfreport": "Selvrapportering",
    "brain": "Hjernetrim",
    "dailyhelp": "Daglig-hjelp",
    "entertainment": "Til glede",
    "contact": "Kontakt",
    "workhelp": "Arbeidsoppgaver",
    "phoneweekcalendar": "Ukeskalender telefon",
    "advice": "Råd og tips",
    "blodpressure": "Høyt blodtrykk",
    "cognitiveloss": "Kognitiv svikt",
    "diabetes1": "Diabetes 1",
    "diabetes": "Diabetes 2",
    "dialog": "Dialog",
    "elearning": "E-læring",
    "exercise": "Trening",
    "heartfailure": "Hjertesvikt",
    "knowledge": "Kunnskap",
    "kols": "Kols",
    "livinghelp": "Bo-hjelp",
    "nutrition": "Ernæring",
    "obesity": "Fedme",
    "schoolhelp": "Skole-hjelp",
    "smartwatch": "Smartklokke",
    "status": "Status"
  },
  "gui": {
    "appname": "memas",
    "close": "Lukk",
    "landingpageheader": "Velkommen til Memas",
    "firsttimeheader": "Førstegangs registrering",
    "offlineheading": "Du er ikke tilkoplet",
    "aboutheader": "Om Memas",
    "privacyheader": "Peronvern",
    "offlinetext": "Vent til du er tilkoplet",
    "onlineheading": "Du er tilkoplet",
    "onlinetext": "Du kan fortsette å arbeide",
    "primaryusers": "Memas primærbruker",
    "calendar": "Kalenderavtaler",
    "pcalendar": "I dag",
    "memorygames": "Hjernetrim",
    "images": "Bilder og kategorier av bilder",
    "pleasure": "Til glede",
    "contact": "Kontakt",
    "selfreporting": "Selvrapport",
    "dailyhelp": "Daglig hjelp",
    "usermanuals": "Veileder",
    "primarylanding": "Memas primærbruker",
    "of": "av",
    "question": "Spørsmål",
    "wrongOTPcode": "Koden du skrev inn fins ikke - prøv igjen",
    "missingValue": "Du må velge et svar før du kan gå videre",
    "nextquestion": "Neste",
    "previousquestion": "Forrige",
    "donequestions": "Tilbake til startskjermen",
    "maxscreen": "Full skjerm",
    "imagelibrary": "Bilder",
    "noimagelibrary": "Ingen tilgjengelige bilder",
    "radiostations": "Radio",
    "noradiostations": "Radiostasjoner",
    "videolibrary": "Videoer",
    "novideolibrary": "Tilgjengelige videoer",
    "stop": "Stop",
    "pause": "Pause",
    "play": "Spill",
    "calnav": "Dager",
    "calmonth": "Måned",
    "contactinformation": "Kontaktperson",
    "replay": "Spill en gang til",
    "completed": "Bra gjennomført",
    "moves": "Klikk:",
    "noimages": "Ingen bilder",
    "noimagesfound": "Det er ikke lagt ut noen bilder som kan vises",
    "nomemoryimages": "Hukommelsesspill",
    "nomemoryimagesfound": "Ingen spill er konfigurert",
    "workhelp": "Jobb hjelp",
    "picturememorygame": "Finn like bilder",
    "week": "Uke",
    "addcalendarevent": "Legg til ny avtale",
    "todayis": "I dag er ",
    "settingsgohome": "Hvis du ser denne siden, klikk i denne boksen for å gå tilbake til startskjermen."
  },
  "webgame": {
    "timehint": "Antall minutter du har spilt dette spillet.",
    "timelabel": "Minutter",
    "timeprefix": ":",
    "timesuffix": " m.",
    "notnumber": "Må være et tall!"
  },
  "sudoku": {
    "gamefinished": "Spillet er ferdig",
    "newgame": "Nytt spill",
    "viewgame": "Vis brettet",
    "notes": "Notater",
    "on": "Av",
    "off": "På",
    "solve": "Løs",
    "reset": "Tilbakespill",
    "delete": "Slett"
  },
  "ms": {
    "title": "Minesveiper",
    "playagain": "Spill igjen?",
    "yes": "Ja",
    "win": "Seier - du vant",
    "loose": "Du tapte...",
    "instructions": "Unngå minene, trykk på en rute, høyre mus-tast for å markere mine."
  },
  "caprino": {
    "title": "Velg film",
    "subtitle": "Du vil ha spørsmål fra...",
    "kappat": "Gutten som kappåt med trollet.",
    "sjuendefar": "Sjuende far i huset.",
    "godehjelpere": "Askeladden og de gode hjelperne.",
    "reveenka": "Reve enka.",
    "results": "Du hadde rett på {numright} spørsmål, av {numquestions} mulige riktige svar.",
    "question": "Spørsmål",
    "correctanswer": "Riktig svar",
    "selectquestion": "Velg riktig svar",
    "wronganswer": "Feil - riktig svar er:",
    "nextquestion": "Neste spørsmål"
  },
  "time": {
    "beforelunch": "Før lunsj",
    "lunch": "Lunsj",
    "afterlunch": "Etter lunsj",
    "dinner": "Middag",
    "afternoon": "Ettermiddag",
    "evening": "Kveld",
    "night": "Natt",
    "morning": "Morgen"
  },
  "tooltip": {
    "help": "Kontekstsenstiv hjelp i Memas"
  },
  "primary": {
    "entercode": "Legg inn koden din for å bruke Memas"
  },
  "secondaryuser": {
    "heading": "Registrering av sekundærbrukere",
    "displayname": "Navn",
    "email": "E-post",
    "phone": "Telefon",
    "skype": "Skype",
    "address": "Adresse",
    "postcode": "Postnummer",
    "place": "Sted",
    "country": "Land",
    "subscriptiontype": "Avtaletype",
    "subscriptionmodel1": "Gratis testbruk - 30 dager",
    "subscriptionmodel2": "Abonnement for 3 måneder - fornyes automatisk",
    "subscriptionmodel3": "Abonnement for 1 år - fornyes automatisk"
  },
  "primaryuser": {
    "heading": "Registrering av primærbrukere",
    "displayname": "Navn",
    "email": "E-post",
    "phone": "Telefon",
    "skype": "Skype",
    "address": "Adresse",
    "postcode": "Postnummer",
    "place": "Sted",
    "country": "Land",
    "registercode": "Registreringskode"
  },
  "cal": {
    "starttime": "Starttid",
    "endtime": "Sluttid",
    "title": "Navn på avtale",
    "alldayevent": "Heldagsavtale",
    "explanation": "Mer informasjon",
    "image": "Bilde av person for avtale",
    "nocaldatatoday": "Ingen avtaler i dag",
    "nocaldataweek": "Ingen avtaler resten av uken"
  },
  "help": {
    "title": "Hvordan bruke Memas",
    "headerhelp": "Kontekstbasert hjelp",
    "landingpage": "https://help.v4l.no/v4l/learner/startpage/",
    "todayswrittenassignments": "https://help.v4l.no/v4l/learner/todayswrittenassignemnts/"
  },
  "icon": {
    "save": "mdi-content-save-outline",
    "schemaupdate": "mdi-update",
    "close": "mdi-close",
    "help": "mdi-help",
    "next": "mdi-arrow-right-bold",
    "previous": "mdi-arrow-left-bold",
    "dots": "mdi-dots-vertical",
    "tryagain": "mdi-refresh",
    "maxscreen": "mdi-fullscreen",
    "stop": "mdi-radio-off",
    "pause": "mdi-pause",
    "play": "mdi-play"
  },
  "wordgame": {
    "title": "Ord i riktig rekkefølge",
    "startgame": "Klikk for å starte spillet",
    "correct": "Bra! riktig svar - prøv igjen",
    "wrong": "Feil svar - prøv igjen",
    "correctAnswer": "Riktig løsning",
    "proposedAnswer": "Ditt forslag",
    "level": "Vanskelighetsgrad",
    "level1": "Lett",
    "level2": "Middels",
    "level3": "Vanskelig",
    "level4": "Utfordrende",
    "easywords": "hund,hus,dyr,mat,vei,sko,hest,hes,ute,valg,radio,due,ku,elg,sau",
    "mediumwords": "bolig,nedover,venstre,epletre,telefon,elefant,vask,spring,fjær,høyre",
    "hardwords": "merkelig,leilighet,bortenfor,hypotese,arbeider,sveiser,elektriker,utfordrende,vanskelig",
    "complexwords": "rundkjøring,hypotenus,kompleksitet,utfordrende,elektriker,motorvei,bortenfor,kompleksitet"
  },
  "numbergame": {
    "title": "Finn {level} riktige tall",
    "explanation": "Du har 10 forsøk på å finne den riktige {level} sifrede koden med unike tall fra 1-9.",
    "guess": "Forslag",
    "goods": "Riktig",
    "corrects": "Tilbakemelding",
    "placeholder": "Skriv inn ditt forslag",
    "again": "Spill en gang til",
    "toastmaxinput": "Ikke mer enn {level} tall",
    "toastunique": "Velg {level} unike tall fra 1-9",
    "toastnoduplicate": "Ingen like tall",
    "reset": "Nullstill",
    "tooltipguess": "Knappene indikere hvor mange tall du har gjettet riktig. Blått er for hvert tall som er riktig, og Grønt for riktig tall i riktig posisjon.",
    "tooltipgoods": "Antall riktige tall på feil plass",
    "tooltipcorrects": "Hvite er feil, Blå er riktig tall, Grønn er riktig plassert",
    "win": "Du vant! Det hemmelige nummeret var: {number}",
    "lost": "Du greide ikke å gjette tallet som var {number}"
  },

  "toast": {
    "calmove": "For å endre en avtale i kalenderen må du snakke med din veileder. Kalenderen er ikke oppdatert",
    "calchanged": "Kalenderen er ikke oppdatert",
    "webgamesaved": "Tid brukt på spill er lagret."
  },
  "selfreporting": {
    "completetitle": "Du har nå gått gjennom alle spørsmålene.",
    "completetext": "Tusen takk!",
    "q1": {
      "q": "Hvem svarte på spørsmålene?",
      "a1": "Brukeren selv.",
      "a2": "Tjenesteyter basert på observasjoner av brukeren.",
      "a3": "Tjenesteyter ved å intervjue brukeren."
    },
    "q2": {
      "q": "I går og i dag, husket du å ta medisinene dine?",
      "a1": "Jeg er sikker på at jeg tok alle medisinene mine.",
      "a2": "Jeg tror jeg glemte å ta noen av medisinene mine.",
      "a3": "Jeg er redd jeg glemte de fleste av medisinene mine.",
      "a4": "Jeg vil ikke ta medisinene mine.",
      "a5": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q3": {
      "q": "Hvordan vil du beskrive humøret ditt i dag?",
      "a1": "Rolig, avslappet, bekymringsløs.",
      "a2": "Glad, spent, livlig.",
      "a3": "Kjeder meg, sliten, dyster, trist.",
      "a4": "Anspent, nervøs, irritert.",
      "a5": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q4": {
      "q": "Har du følt deg ukonsentrert i det siste?",
      "a1": "Ingen endring.",
      "a2": "Noe forverring de siste dagene eller ukene.",
      "a3": "Merkbar forverring de siste dagene eller ukene.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q5": {
      "q": "Har du i det siste hatt problemer med å huske navn du bør huske (pårørende, venner, familiemedlemmer)",
      "a1": "Ingen endring.",
      "a2": "Noe forverring de siste dagene eller ukene.",
      "a3": "Merkbar forverring de siste dagene eller ukene.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q6": {
      "q": "Har du i det siste opplevd å legge fra deg ting på feil plass hjemme?",
      "a1": "Ingen endring.",
      "a2": "Noe forverring de siste dagene eller ukene.",
      "a3": "Merkbar forverring de siste dagene eller ukene.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q7": {
      "q": "Har du i det siste hatt problemer med å finne ting hjemme?",
      "a1": "Ingen endring.",
      "a2": "Noe forverring de siste dagene eller ukene.",
      "a3": "Merkbar forverring de siste dagene eller ukene.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q8": {
      "q": "Har du i det siste hatt problemer med å finne veien hjem når du er ute og går?",
      "a1": "Ingen endring.",
      "a2": "Noe forverring de siste dagene eller ukene.",
      "a3": "Merkbar forverring de siste dagene eller ukene.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q9": {
      "q": "Har du i det siste opplevd problemer med å huske hvordan du bruker husholdningsmaskiner (f.eks. kaffemaskin, TV-tuner/fjernkontroll eller vaskemaskin)?",
      "a1": "Ingen endring.",
      "a2": "Noe forverring de siste dagene eller ukene.",
      "a3": "Merkbar forverring de siste dagene eller ukene.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q10": {
      "q": "Har du i det siste hatt problemer med å huske navn på ting, gjenstander, steder osv.?",
      "a1": "Ingen endring.",
      "a2": "Noe forverring de siste dagene eller ukene.",
      "a3": "Merkbar forverring de siste dagene eller ukene.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q11": {
      "q": "Har du følt deg uvel de siste dagene?",
      "a1": "Nei, ikke i det hele tatt.",
      "a2": "Ja, litt eller noen ganger.",
      "a3": "Ja, temmelig mye.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q12": {
      "q": "Har du vært dårlig i magen de siste dagene?",
      "a1": "Nei, ikke i det hele tatt.",
      "a2": "Ja, litt eller noen ganger.",
      "a3": "Ja, temmelig ofte.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q13": {
      "q": "Har du de siste dagene hatt dårlig apetitt?",
      "a1": "Nei, ikke i det hele tatt.",
      "a2": "Ja, litt eller noen ganger.",
      "a3": "Ja, temmelig ofte.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q14": {
      "q": "Har du de siste dagene følt deg svimmel?",
      "a1": "Nei, ikke i det hele tatt.",
      "a2": "Ja, litt eller noen ganger.",
      "a3": "Ja, temmelig ofte.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q15": {
      "q": "Har du de siste dagene følt deg mer slapp enn vanlig?",
      "a1": "Nei, ikke i det hele tatt.",
      "a2": "Ja, litt eller noen ganger.",
      "a3": "Ja, temmelig ofte.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q16": {
      "q": "Har du de siste nettene hatt søvnproblemer?",
      "a1": "Nei, ikke i det hele tatt.",
      "a2": "Ja, litt eller noen ganger.",
      "a3": "Ja, temmelig ofte.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q17": {
      "q": "Har du de siste dagene hatt vanskeligheter med å finne de riktige ordene?",
      "a1": "Nei, ikke i det hele tatt.",
      "a2": "Ja, litt eller noen ganger.",
      "a3": "Ja, temmelig ofte.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q18": {
      "q": "Har du de siste dagene hatt problemer med resonnement og dømmekraft, f.eks. brukt for mye penger hatt dårlig personlig pleie?",
      "a1": "Nei, ikke i det hele tatt.",
      "a2": "Ja, litt eller noen ganger.",
      "a3": "Ja, temmelig mye.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    },
    "q19": {
      "q": "Har du de siste dagene hatt problemer med å opprettholde dine sosiale kontakter eller være sammen med andre?",
      "a1": "Nei, ikke i det hele tatt.",
      "a2": "Ja, litt eller noen ganger.",
      "a3": "Ja, temmelig mye.",
      "a4": "Jeg vil eller kan ikke svare på dette spørsmålet."
    }
  }
}
