<template>
    <v-app v-if="getLoggedIn()" id="app">
        <PhoneNavigationSystem v-if="isPhone()" />
        <NavigationSystem v-else />
        <v-main :key="updatekey" class="content">
            <router-view />
            <v-snackbar
                v-model="updateExists"
                color="rgb(var(--color-brand-100))"
                location="top"
                :close-on-content-click="true"
                :timeout="-1"
            >
                {{ $t('gui.updateavailable') }}
                <v-btn text @click="refreshApp">
                    {{ $t('gui.update') }}
                </v-btn>
            </v-snackbar>
            <v-snackbar
                v-model="isOffline"
                location="top"
                :timeout="snackbarTimeout"
                color="rgb(var(--color-offline-100))"
            >
                <v-card variant="flat" color="rgb(var(--color-offline))">
                    <v-card-title class="snackbarHeading">
                        {{ $t('gui.offlineheading') }}
                    </v-card-title>
                    <v-card-text class="snackbarText">
                        {{ $t('gui.offlinetext') }}
                    </v-card-text>
                </v-card>
            </v-snackbar>
            <v-snackbar
                v-model="isOnline"
                location="top"
                :timeout="snackbarTimeout"
                :close-on-content-click="true"
                color="rgb(var(--color-online-100))"
                transition="v-fab-transition"
            >
                <v-progress-linear
                    color="var(--color-brand)"
                    :model-value="incrementSeconds"
                    height="5"
                    rounded
                />
                <v-card variant="flat" color="rgb(var(--color-online))">
                    <v-card-title class="snackbarHeading">
                        {{ $t('gui.onlineheading') }}
                    </v-card-title>
                    <v-card-text class="snackbarText">
                        {{ $t('gui.onlinetext') }}
                    </v-card-text>
                </v-card>
            </v-snackbar>
            <v-snackbar
                v-model="hasUpdate"
                location="top"
                :close-on-content-click="true"
                :timeout="snackbarTimeout"
                color="rgb(var(--color-offline-100))"
            >
                <v-card variant="flat" color="rgb(var(--color-offline))">
                    <v-card-title class="snackbarHeading">
                        {{ $t('gui.updateavailable') }}
                    </v-card-title>
                    <v-card-text class="snackbarText">
                        {{ $t('gui.offlinetext') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="refreshApp">
                            {{ $t('gui.update') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-snackbar>
            <v-sheet
                v-if="viewRadioControls()"
                width="400px"
                height="70px"
                elevation="10"
                rounded="10px"
                class="radioControls"
                location="#app"
                :no-click-animation="true"
                :retain-focus="false"
                :position="fixed"
                color="var(--color-footer-background)"
            >
                <v-card width="400px" height="70px" color="var(--color-white)">
                    <v-card-actions>
                        <v-btn
                            size="x-large"
                            :text="$t('gui.play')"
                            :append-icon="$t('icon.play')"
                            @click="playAudio()"
                        />
                        <v-spacer />
                        <v-btn
                            size="x-large"
                            :text="$t('gui.pause')"
                            :append-icon="$t('icon.pause')"
                            @click="pauseAudio()"
                        />
                        <v-spacer />
                        <v-btn
                            size="x-large"
                            :text="$t('gui.stop')"
                            :append-icon="$t('icon.stop')"
                            @click="stopAudio()"
                        />
                    </v-card-actions>
                </v-card>
            </v-sheet>
        </v-main>
        <PhoneFooterMenu v-if="isPhone()" />
        <FooterMenu v-else />
    </v-app>
    <v-app v-else>
        <PrimaryLandingPage />
    </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'pinia'

// import { RouterLink, RouterView } from "vue-router";
import { adminStore } from '@/stores/adminstore.js'
import { userStore } from '@/stores/userstore.js'
import { defineAsyncComponent } from 'vue'

// import AnalogClock from '@/components/primary/AnalogClock.vue'

import update from './mixins/update'

import { registerSW } from 'virtual:pwa-register'

const updateSW = registerSW({
    immediate: true
    //  onNeedRefresh() {},
    //  onOfflineReady() {}
})
let aStore = null
export default {
    name: 'App',
    components: {
        FooterMenu: defineAsyncComponent(
            () => import('@/components/menues/FooterMenu.vue')
        ),
        PhoneFooterMenu: defineAsyncComponent(
            () => import('@/components/menues/PhoneFooterMenu.vue')
        ),
        NavigationSystem: defineAsyncComponent(
            () => import('@/components/menues/NavigationSystem.vue')
        ),
        PhoneNavigationSystem: defineAsyncComponent(
            () => import('@/components/menues/PhoneNavigationSystem.vue')
        ),
        PrimaryLandingPage: defineAsyncComponent(
            () => import('@/components/primary/PrimaryLandingPage.vue')
        )
    },
    mixins: [update],
    beforeRouteEnter(to, from) {
        this.$info('------- APP VUE BEFORE ENTER -------- (%s) (%s)', to, from)
        console.log('======== WATCH SET CONTROLS (%s)', this.radioControls)
    },
    setup() {
        aStore = new adminStore()
    },
    computed: {
        ...mapState(adminStore, ['getRadioChannel', 'getShowRadioControls'])
    },
    watch: {
        getRadioChannel(ny, old) {
            console.log('>>>>>>>>> WATCH >>>>>>>>>>>>>>')
            console.log('radiochannel changed (%j) (%j)', old, ny)
            aStore = new adminStore()
            aStore.showRadioControls = true
            this.radioControls = true
            if (ny === null) {
                aStore.showRadioControls = false
                this.radioControls = false
            }
        },
        getShowRadioControls(ny, old) {
            console.log('>>>>>>>>> WATCH RADIO CONTROLS >>>>>>>>>>>>>>')
            console.log('showRadioControls changed old (%s) ny(%s)', old, ny)
            this.radioControls = ny
            console.log('======== WATCH SET CONTROLS (%s)', this.radioControls)
        }
    },
    setup() {},
    data() {
        return {
            errorCapture: false,
            snackbarTimeout: -1,
            isOnline: false,
            isOffline: false,
            hasUpdate: false,
            incrementSeconds: 0,
            gotoToDay: null,
            radioControls: false,
            updatekey: 0
        }
    },
    updated() {
        const aStore = adminStore()
        this.radioControls = aStore.showRadioControls
        console.log('======== UPDATED SET CONTROLS (%s)', this.radioControls)
    },
    beforeMount() {
        const aStore = adminStore()
        const uStore = userStore()

        console.log(
            'APP BEFORE MOUNT <<<<<<<<<<<<< (%s) (%s)',
            location.hostname,
            localStorage.getItem('lang')
        )
        let agent = navigator.userAgent
        console.info(
            'USER AGENT (%s) (%s) (%s)',
            screen.width,
            agent,
            agent.search('Android')
        )

        let serverUrl = localStorage.getItem('memasserver') || ''
        let registryUrl = localStorage.getItem('memasregistry') || ''
        var userLang = navigator.language || navigator.userLanguage
        console.info('BROWSER LANG IS (%s)', userLang)
        let langComponents = userLang.split('-')
        if (langComponents.length > 0) {
            userLang = langComponents[0]
        }

        aStore.setLang(localStorage.getItem('lang') || userLang)
        let localLang = localStorage.getItem('lang') || userLang
        aStore.setLang(localLang)
        this.$root.$i18n.locale = localLang
        this.$date.locale(localLang)
        this.$i18n.locale = aStore.lang
        let token = localStorage.getItem('token') || ''
        // let sid = localStorage.getItem('sid') || ''
        if (serverUrl !== '' && registryUrl !== '' && token !== '') {
            aStore.setServerUrl(serverUrl)
            aStore.setRegistryUrl(registryUrl)
            uStore.serveraccesstoken = token
            //  aStore.setSid(sid)
        } else {
            console.info('*** MISSING SOME ESENNTIAL REGISTRY INFORMATION ***')
            if (location.hostname === 'memas.app') {
                aStore.setServername('https://www.ataa.no/')
            } else if (location.hostname === 'stage.memas.app') {
                aStore.setServername('https://www.ataa.no/stage')
            } else if (location.hostname === 'localhost') {
                console.log('WE HAVE LOCALHOST (%s)', location.hostname)
                aStore.setServername('http://localhost/')
            }
            aStore
                .addRegistryUrl()
                .then(() => {
                    this.$router
                        .push({
                            path: '/'
                        })
                        .catch(() => {})
                })
                .catch(() => {
                    this.$router
                        .push({
                            path: '/'
                            //      path: '/Error'
                        })
                        .catch(() => {})
                })
        }
        if (location.hostname === 'memas.app') {
            aStore.setEnvironment('prod')
        } else if (location.hostname === 'stage.memas.app') {
            aStore.setEnvironment('stage')
        } else if (location.hostname === 'localhost') {
            aStore.setEnvironment('dev')
        }
    },
    mounted() {
        console.info('***===*** APP MOUNTED ***===***')
        this.checkLastInteraction()
        this.gotoToDay = setInterval(this.checkLastInteraction, 60000)
        window.addEventListener('offline', () => {
            // Update your UI to reflect that there's no connection.
            console.warn('*** WARN *** we are OFFLINE !')

            this.snackbarTimeout = -1
            this.isOnline = false
            this.hasUpdate = false
            this.isOffline = true
        })
        window.addEventListener('online', () => {
            // Update your UI to reflect that the connection is back.
            console.warn('*** WARN *** we are back ONLINE !')
            this.incrementSeconds = 0
            this.snackbarTimeout = 8000
            this.isOffline = false
            this.hasUpdate = false
            this.isOnline = true
            this.countSeconds()
        })
        window.addEventListener('swUpdate', () => {
            // Update your UI to reflect that the connection is back.
            console.warn('*** WARN *** we have Update !')
            this.incrementSeconds = 0
            this.snackbarTimeout = 8000
            this.isOffline = false
            this.isOnline = false
            this.hasUpdate = true
            // this.countSeconds();
        })
        // this.setFullscreen(document.documentElement)
    },
    methods: {
        ...mapState(userStore, ['getLoggedIn']),
        ...mapActions(adminStore, [
            'setEnvironment',
            'setServername',
            'addRegistryUrl',
            'setLang',
            'setFullscreen'
        ]),
        ...mapGetters(adminStore, ['viewRadioControls']),
        stopAudio() {
            let aStore = adminStore()
            if (aStore.radioChannel !== null) {
                aStore.radioChannel.pause()
                aStore.radioChannel = null
                //  aStore.radioChannel = new Audio("");
                aStore.showRadioControls = false
            }
        },
        pauseAudio() {
            let aStore = adminStore()
            aStore.radioChannel.pause()
        },
        playAudio() {
            let aStore = adminStore()
            aStore.radioChannel.play()
        },
        isPhone() {
            let aStore = adminStore()
            let agent = navigator.userAgent
            console.info(
                'USER AGENT (%s) (%s) (%s)',
                screen.width < 601,
                agent,
                agent.search('Android')
            )
            if (screen.width < 601) {
                aStore.isPhone = true
                return true
            } else {
                aStore.isPhone = false
                return false
            }
            /*
      else if (agent.search("iPhone") > 0) {
        aStore.isPhone = true;
        return true;
      }
      */
        },
        countSeconds() {
            setInterval(() => {
                let sec = this.incrementSeconds + 1
                this.incrementSeconds = sec
            }, 130)
        },
        // https://vite-pwa-org.netlify.app/guide/prompt-for-update.html
        onNeedRefresh() {
            console.log('******* NEEED REFRESH ******* (app.vue)')
            this.updateExists = true
            updateSW()
        },
        onOfflineReady() {
            console.log('******** ON OFFLINE READY ******* (app.vue)')
        },
        checkLastInteraction() {
            let aStore = new adminStore()
            let resetTime = this.$date(aStore.lastInteraction).add(
                aStore.resetTimeLimit,
                'minutes'
            )
            console.log(
                'CheckLastInteraction got called ! (%s)',
                this.$date().isAfter(resetTime, 'minutes')
            )
            console.log(
                'last Interaction: (%s)',
                aStore.lastInteraction.format()
            )
            console.log('resetTime Interaction: (%s)', resetTime.format())
            if (this.$date().isAfter(resetTime, 'minutes')) {
                aStore.isdisabled = false
                this.updatekey++
                this.$router.push({
                    name: 'PrimaryStartPage'
                })
            }
        }
    }
}
</script>

<style scoped>
.radioControls {
    position: fixed;
    top: 3px;
    left: 80px;
    z-index: 6000;
}
</style>
